export const firstScreenPreloader = () => {
  const page = document.querySelector("#page");
  const header = document.querySelector("#header");
  let preloaderDuration = 3000;
  const href = window.location.href;
  if (href.indexOf("#") >= 0) {
    preloaderDuration = 0;
  }

  const firstScreens = document.querySelectorAll(".first-screen");

  if (firstScreens.length === 0) {
    page.classList.remove("h-full");
  }

  firstScreens.forEach((screen) => {
    if (screen.classList.contains("preloader")) {
      const image = screen.querySelector(".first-screen-image");
      const button = screen.querySelector(".first-screen-button");
      const logo = screen.querySelector(".first-screen-logo");

      if (image) {
        image.style.transition = `transform ${preloaderDuration}ms`;
      }
      if (button) {
        button.style.transition = `all .4s ${preloaderDuration - 200}ms`;
      }
      if (logo) {
        logo.style.transition = `all ${preloaderDuration}ms`;
      }
      header.style.transition = `transform .3s`;

      screen.classList.remove("at-work");
      setTimeout(() => {
        header.classList.add("active");
      }, preloaderDuration - 500);
      setTimeout(() => {
        page.classList.remove("h-full");
      }, preloaderDuration - 100);
    } else {
      page.classList.remove("h-full");
    }
  });
};

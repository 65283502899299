import { anchorLink } from "./modules/anchor-link.js";
import { cursor } from "./modules/cursor.js";
import { marquee } from "./modules/marquee.js";
import { burger } from "./modules/header/burger.js";
import { contactForm } from "./modules/contact-form.js";
import { blog } from "./modules/blog.js";
import { faq } from "./modules/faq.js";
import { firstScreenPreloader } from "./modules/first-screen-preloader.js";
import { scroll } from "./modules/section-scroll.js";
import { cursorBg } from "./modules/cursor-bg.js";
import { fixedTitle } from "./modules/fixed-title.js";

document.addEventListener("DOMContentLoaded", function (event) {
  firstScreenPreloader();
  anchorLink();
  marquee();
  fixedTitle();
  blog();
  burger();
  contactForm();
  faq();
  cursor();
  cursorBg();
  // scroll();
});

export const burger = () => {
  const btns = document.querySelectorAll(".header-mobile-burger");
  const backings = document.querySelectorAll(".header-mob-backing");
  const menus = document.querySelectorAll(".header-menu-mob");
  const page = document.querySelector("#page");

  btns.forEach((btn) => {
    btn.addEventListener("mouseenter", () => {
      btn.classList.add("hover");
    });
    btn.addEventListener("mouseleave", () => {
      btn.classList.remove("hover");
    });
    btn.addEventListener("click", () => {
      btn.classList.toggle("active");
      btn.classList.remove("hover");
      toggleMenu();
    });
  });

  const toggleMenu = () => {
    backings.forEach((backing) => {
      backing.classList.toggle("bg-blue");
    });
    menus.forEach((menu) => {
      menu.classList.toggle("scale-y-0");
    });
    page.classList.toggle("active");
    document.querySelector("body").classList.toggle("overflow-hidden");
  };
};

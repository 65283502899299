import {isMobile} from "./isMobile.js";

export const cursorBg = () => {
    const cursor = document.querySelector('.cursor-dot');
    if (cursor && !isMobile.any()) {
        const events = ['mousemove', 'mouseover', 'click', 'wheel'];
        const func = (e) => {
            if (e.target.closest('section') || e.target.closest('footer') || e.target.closest('div')) {
                let section = e.target.closest('div');
                if (e.target.closest('section')) {
                    section = e.target.closest('section');
                } else if (e.target.closest('footer')) {
                    section = e.target.closest('footer');
                }
                let bgc = window.getComputedStyle(section, null).getPropertyValue("background-color");
                if (bgc === 'rgba(0, 0, 0, 0)' || bgc === 'rgba(255, 255, 255, 1)' || bgc === 'rgb(255, 255, 255)' || bgc === '#fff') {
                    cursor.classList.add('c-dark');
                } else {
                    cursor.classList.remove('c-dark');
                }
            } else {
                cursor.classList.remove('c-dark');
            }
        }
        events.forEach(event => {
            document.addEventListener(event, (e) => {
                func(e);
            });
        });
    }

}
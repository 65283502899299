import {isMobile} from "./isMobile.js";

export const scroll = () => {
    const body = document.querySelector("body");
    if (body.classList.contains("home")) {
        const scroll = () => {
            if (window.matchMedia("(max-height: 1200px)").matches && !isMobile.any()) {
                $("section, footer").each(function () {
                    $(this).addClass("scroll");
                });
                $.scrollify({
                    section: ".scroll",
                    scrollSpeed: 500,
                    easing: "linear",
                    scrollbars: true,
                    updateHash: false,
                    touchScroll: false,
                    setHeights: false,
                });
            } else {
                scrollify.destroy();
                $("section, footer").each(function () {
                    $(this).removeClass("scroll");
                });
            }
        };
        scroll();
        window.addEventListener('click', () => {
            $.scrollify.update();
        });
        window.addEventListener('resize', () => {
            $.scrollify.update();
        });
        window.addEventListener("orientationchange", function() {
            $.scrollify.update();
        }, false);
    }
};

export const blog = () => {
  $(".hover-blog").hover(
    function () {
      $(this).parents(".post").addClass("post-hover");
    },
    function () {
      $(this).parents(".post").removeClass("post-hover");
    }
  );
  const kitContents = document.querySelector(".single-post-info");
  const kitNav = document.querySelector(".single-post-share-container");

  const stickyScroll = () => {
    if (kitContents) {
      let windowTop = window.pageYOffset + 95;
      let windowHeight = window.innerHeight;
      let windowBottom = windowTop + windowHeight;

      let wrapperHeight = kitContents.offsetHeight;
      let wrapperTop = kitContents.offsetTop;
      let wrapperLeft = kitContents.offsetLeft;
      let wrapperRight = wrapperLeft + kitContents.offsetWidth;
      let wrapperBottom = wrapperTop + wrapperHeight;

      let top = wrapperTop - windowTop;
      let bottom = windowBottom - wrapperBottom;

      if (top >= 0 && bottom < 0) {
        kitNav.style.height = `${windowHeight - top - 60}px`;
      } else if (top < 0 && bottom < 0) {
        kitNav.style.height = `${windowHeight - 60}px`;
      } else if (top < 0 && bottom >= 0) {
        kitNav.style.height = `${windowHeight - bottom - 30}px`;
      }

      let stickyHeight = kitNav.offsetHeight;

      if (wrapperHeight > stickyHeight) {
        if (
          windowTop >= wrapperTop &&
          kitNav.offsetTop + stickyHeight < wrapperBottom
        ) {
          kitNav.classList.add("fixed");
        } else if (
          windowTop < wrapperTop &&
          kitNav.offsetTop + stickyHeight < wrapperBottom
        ) {
          kitNav.classList.remove("fixed");
        }
      } else {
        kitContents.style.minHeight = `${stickyHeight}px`;
      }
    }
  };
  stickyScroll();
  window.addEventListener("scroll", () => {
    stickyScroll();
  });
  window.addEventListener("resize", () => {
    stickyScroll();
  });
};

export const fixedTitle = () => {

    const body = document.querySelector("body");
    const bodyBgc = window.getComputedStyle(body, null).backgroundColor;

    if (body.classList.contains("home")) {
        const colors = {
            'rgb(26, 25, 25)' : 'bg-black',
            'rgba(0, 0, 0, 0)' : 'bg-white',
            'rgb(255, 255, 255)' : 'bg-white',
            'rgb(52, 52, 52)' : 'bg-dark_grey',
            'rgb(1, 242, 153)' : 'bg-light_green',
            'rgb(14, 14, 192)' : 'bg-blue',
            'rgb(217, 217, 217)' : 'bg-light_grey',
        }
        const titles = document.querySelectorAll('.marquee-title-new');

        titles.forEach(title => {
            if(title.classList.contains('absolute') || title.classList.contains('fixed')){
                let closestSection = title.closest('section');
                closestSection.classList.add('relative');
                let closestSectionStyles = window.getComputedStyle(closestSection, null);
                let closestSectionBgColor = closestSectionStyles.getPropertyValue("background-color");
                for (const color in colors) {
                    if(closestSectionBgColor === color){
                        title.classList.add(colors[color]);
                    }
                }
            }
        });

        const func = () => {
            titles.forEach(title => {
                if(title.classList.contains('absolute') || title.classList.contains('fixed')){
                    let height = title.offsetHeight;
                    let closestSection = title.closest('section');
                    let closestSectionTop = closestSection.offsetTop;
                    let closestSectionBottom = closestSectionTop + closestSection.offsetHeight - height;
                    if(window.pageYOffset >= closestSectionTop && window.pageYOffset <= closestSectionBottom){
                        title.classList.add('fixed');
                        title.classList.remove('absolute');
                        title.style.top = '';
                    } else {
                        if(window.pageYOffset < closestSectionTop){
                            title.style.top = '';
                        } else {
                            title.style.top = `calc(100% - ${height}px)`;
                        }
                        title.classList.remove('fixed');
                        title.classList.add('absolute');
                    }
                }
            });
        }
        func();

        window.addEventListener('scroll', () => {
            func();
        });
    }

}
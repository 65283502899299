export const marquee = () => {
  let homeSections = $(".home section");
  let gap = 100;
  let speed=5000;
  if (window.matchMedia("(min-width: 1280px)").matches) {
    gap = 500;
    speed=9000;
  } else if (window.matchMedia("(min-width: 1024px)").matches) {
    gap = 300;
    speed=7000;
  } else if (window.matchMedia("(min-width: 639px)").matches) {
    gap = 150;
    speed=6000;
  }

  const marqueesText = (elements) => {
    const arr = [];
    elements.each(function () {
      let text = $(this).text();
      $(this).append(`<span>${text}</span>`);
      let textWidth = $(this).find("span").outerWidth();
      $(this)
        .find("span")
        .each(function () {
          $(this).remove();
        });
      arr.push(Math.abs($(window).innerWidth() - textWidth));
    });

    elements.each(function (index) {
      let thisGap = arr[index] >= gap ? arr[index] : gap;
      $(this).marquee({
        duration: speed,
        gap: thisGap,
        delayBeforeStart: 0,
        direction: "left",
        duplicated: true,
        startVisible: false,
      });
      $(this).css("visibility", "visible");
    });
  };
  if (homeSections.length > 0) {
    const activeSection = () => {
      let windowTop = window.pageYOffset;
      let windowBottom = windowTop + window.innerHeight;
      homeSections.each(function (index) {
        if ($(this).offset().top <= windowBottom) {
          if (!$(this).hasClass("active-title")) {
            $(this).addClass("active-title");
            marqueesText($(this).find(".marquee-title-new"));
          }
        }
      });
    };
    activeSection();
    window.addEventListener("scroll", () => {
      activeSection();
    });
    window.addEventListener("resize", () => {
      activeSection();
    });
    window.addEventListener(
      "orientationchange",
      function () {
        activeSection();
      },
      false
    );
  } else {
    marqueesText($(".marquee-title-new"));
  }
};

export const faq = () => {
    const triggers = document.querySelectorAll(".faq-title");
    triggers.forEach((trigger) => {
        let triggerSiblings = trigger.closest(".faq-items").querySelectorAll(".faq-title");
        let contents = trigger.closest(".faq-items").querySelectorAll(".faq-content");

        trigger.addEventListener("mouseenter", () => {
            trigger.classList.add("hover");
        });
        trigger.addEventListener("mouseleave", () => {
            trigger.classList.remove("hover");
        });

        trigger.addEventListener("click", (e) => {
            triggerSiblings.forEach((triggerSibling) => {
                if (e.target.closest(".faq-title") !== triggerSibling) {
                    triggerSibling.classList.remove("active");
                }
            });
            contents.forEach((content) => {
                if (e.target.closest(".faq-title").nextElementSibling !== content) {
                    content.classList.add("hidden");
                } else {
                    content.classList.toggle("hidden");
                }
            });
            trigger.classList.toggle("active");
            trigger.classList.remove("hover");
        });
    });
};
